import React, { FC, useCallback, useMemo, useRef } from 'react'
import {
    ArticleTeaserProps,
    BasicTeaserProps,
    ComponentType,
    GalleryTeaserProps,
    StreamTeaserProps,
    TeaserLabelType,
    TeaserProps,
    VideoTeaserProps,
} from '@sport1/types/web'
import TeaserComponent from '@sport1/news-styleguide/Teaser'
import Types from '@sport1/news-styleguide/Types'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { useRouter } from 'next/router'
import { publishDateIsCurrentDate } from '@/helpers/dateHelper'
import { mapDeeplink } from '@/helpers/deeplink/mapper'
import { getArticleSlug, getGallerySlug, getVideoSlug } from '@/helpers/slugHelper'
import useInViewport from '@/hooks/useInViewport'
import { ComponentProps, TeaserSize, TeaserSizeTypes } from '@/types/teaser'
import { mapTrackingData, useTracking } from '@/utils/tracking/TrackingProvider'
import nofollowExternal from '@/utils/url/nofollowExternal'
import { takeLastTagWithKeys } from '@/helpers/tagsHelper'

type Props = {
    backgroundColor?: 'pearl' | 'onyx'
    title?: string
    size?: TeaserSize
    teaser: TeaserProps
    expand?: boolean
    isCardTeaser?: boolean
    headlineNumberOfLines?: number
    verticalExpand?: boolean
    hasBorder?: boolean
    isItTwoTeasersRightColumn?: boolean
}

export const LabelTypesMap: Map<
    TeaserLabelType | undefined,
    'live' | 'onyx' | 'breaking' | undefined
> = new Map([
    ['LIVE', 'live'],
    ['BREAKING_NEWS', 'breaking'],
    ['EXCLUSIVE', 'onyx'],
    ['UPDATE', 'onyx'],
    ['COLUMN', 'onyx'],
    ['COMMENT', 'onyx'],
    ['BRANDSTORY', 'onyx'],
    ['SPONSORED', 'onyx'],
])

export const LabelTextMap: Map<TeaserLabelType | undefined, string | undefined> = new Map([
    ['LIVE', 'live'],
    ['EXCLUSIVE', 'EXKLUSIV'],
    ['UPDATE', 'UPDATE'],
    ['COLUMN', 'KOLUMNE'],
    ['COMMENT', 'KOMMENTAR'],
    ['BRANDSTORY', 'BRAND STORY'],
    ['SPONSORED', 'SPONSORED'],
])

const Teaser: FC<Props> = ({
    backgroundColor,
    teaser,
    size = TeaserSizeTypes.BIG,
    expand,
    headlineNumberOfLines,
    hasBorder,
    isItTwoTeasersRightColumn,
}) => {
    const trackingData = mapTrackingData(teaser.meta?.tracking)
    const { trackTeaserImpression, trackTeaserClick } = useTracking()
    const ref = useRef<HTMLDivElement>(null)
    const isVisible = useInViewport(ref)
    const webTarget = teaser.target ? teaser.target : '_self'
    //margin added only for countdown, after em we should get rid of useRouter below and mocks in tests
    //to find mocks check this MR -> https://gitlab.sport1dev.de/ng/Outgest/Edge/sport1-website/-/merge_requests/1603/diffs
    const { asPath } = useRouter()
    React.useEffect(() => {
        if (isVisible) {
            trackTeaserImpression(trackingData)
        }
    }, [isVisible, trackingData, trackTeaserImpression])

    const getLabelProps = useCallback(
        (
            rawLabel: TeaserLabelType | undefined,
            transfer?: boolean
        ):
            | [Types.TeaserLabelProps]
            | [Types.TeaserLabelProps, Types.TeaserLabelProps]
            | undefined => {
            const type = transfer ? 'transfer' : LabelTypesMap.get(rawLabel)
            const text = LabelTextMap.get(rawLabel)
            if (type) {
                if (type === 'onyx') {
                    if (text) {
                        return [{ labelType: type, labelText: text }]
                    }
                } else {
                    return [{ labelType: type }]
                }
            }
        },
        []
    )

    const getBasicProps = useCallback(
        (
            data: BasicTeaserProps & Pick<Partial<ArticleTeaserProps>, 'transfer'>
        ): ComponentProps => {
            const { tags, publishedAt, title, label, description, transfer, summary, imageUrl } =
                data
            const tag = takeLastTagWithKeys(tags, ['iconUrl', 'title', 'iconUrlType'])
            return {
                tag: {
                    name: tag?.title || '',
                    imageUrl: tag?.iconUrl,
                    imageAlt: tag?.title,
                    iconUrlType: tag?.iconUrlType,
                },
                mainImageUrl: imageUrl ?? '',
                mainImageAlt: description || title,
                published: publishedAt ? publishDateIsCurrentDate(publishedAt) : undefined,
                headlineText: title,
                type: 'ARTICLE',
                backgroundColor,
                textColor: backgroundColor === 'onyx' ? 'pearl' : 'onyx',
                onPress: () => trackTeaserClick(trackingData),
                labels: getLabelProps(label, transfer),
                abstract: summary,
                hasBorder,
            }
        },
        [backgroundColor, trackingData, getLabelProps, trackTeaserClick, hasBorder]
    )

    const getArticleProps = useCallback(
        (article: ArticleTeaserProps): ComponentProps => {
            return {
                ...getBasicProps(article),
                href: getArticleSlug(article),
                type: 'ARTICLE',
            }
        },
        [getBasicProps]
    )

    const getStreamTeaserProps = useCallback(
        (stream: StreamTeaserProps): ComponentProps => {
            return {
                ...getBasicProps(stream),
                type: 'ARTICLE',
                href: mapDeeplink(stream.url),
            }
        },
        [getBasicProps]
    )

    const getGalleryProps = useCallback(
        (gallery: GalleryTeaserProps): ComponentProps => {
            return {
                ...getBasicProps(gallery),
                href: getGallerySlug(gallery),
                type: 'GALLERY',
            }
        },
        [getBasicProps]
    )

    const getVideoProps = useCallback(
        (video: VideoTeaserProps): ComponentProps => {
            return {
                ...getBasicProps(video),
                videoDuration: video.duration,
                href: getVideoSlug(video, true),
                type: 'VIDEO',
            }
        },
        [getBasicProps]
    )

    const getComponentProps = useCallback((): ComponentProps => {
        switch (teaser.type) {
            case ComponentType.GALLERY_TEASER:
                return getGalleryProps(teaser as GalleryTeaserProps)
            case ComponentType.VIDEO_TEASER:
                return getVideoProps(teaser as VideoTeaserProps)
            case ComponentType.EDITORIAL_STREAM_TEASER:
                return getStreamTeaserProps(teaser as StreamTeaserProps)
            default:
                return getArticleProps(teaser as ArticleTeaserProps)
        }
    }, [getArticleProps, getGalleryProps, getVideoProps, getStreamTeaserProps, teaser])

    const teaserProps = getComponentProps()

    return useMemo(() => {
        switch (size) {
            case TeaserSizeTypes.HORIZONTAL_BIG:
                // extra container because ref is not defined in HorizontalBigTeaserComponent because of dynamic import
                return (
                    <NonFlexingContainer
                        innerRef={ref}
                        //margin added only for countdown, after em we should get rid of margintop below
                        marginTop={
                            asPath === '/channel/fussball/em'
                                ? ['spacing-6', 'spacing-6', 'spacing-6', 'spacing-11']
                                : 'spacing-none'
                        }
                    >
                        <TeaserComponent
                            variant={[
                                'HorizontalTeaserL',
                                'HorizontalTeaserL',
                                'HorizontalTeaserXL',
                                isItTwoTeasersRightColumn
                                    ? 'HorizontalTeaserL'
                                    : 'HorizontalTeaserXL',
                            ]}
                            testID={`${teaser.type}_horizontal_bigTeaser_${teaserProps.headlineText}`}
                            webRel={nofollowExternal}
                            webTarget={webTarget}
                            {...teaserProps}
                        />
                    </NonFlexingContainer>
                )
            case TeaserSizeTypes.BIG:
                return (
                    <NonFlexingContainer
                        innerRef={ref}
                        //margin added only for countdown, after em we should get rid of margintop below
                        marginTop={asPath === '/channel/fussball/em' ? 'spacing-6' : 'spacing-none'}
                    >
                        <TeaserComponent
                            variant={['VerticalTeaserM', 'VerticalTeaserM', 'VerticalTeaserL']}
                            testID={`${teaser.type}_bigTeaser_${teaserProps.headlineText}`}
                            {...teaserProps}
                            innerRef={ref}
                            webTarget={webTarget}
                            teaserIcon={teaser.teaserIcon}
                            webRel={nofollowExternal}
                        />
                    </NonFlexingContainer>
                )
            case TeaserSizeTypes.MID:
                return (
                    <TeaserComponent
                        variant={!expand ? 'VerticalTeaserXS' : 'VerticalTeaserS'}
                        testID={`${teaser.type}_midTeaser_${teaserProps.headlineText}`}
                        {...teaserProps}
                        innerRef={ref}
                        headlineNumberOfLines={headlineNumberOfLines || 2}
                        webRel={nofollowExternal}
                        webTarget={webTarget}
                        teaserIcon={teaser.teaserIcon}
                        noExpand={!expand}
                    />
                )
            case TeaserSizeTypes.SMALL:
                return (
                    <TeaserComponent
                        variant="HorizontalTeaserS"
                        testID={`${teaser.type}_smallTeaser_${teaserProps.headlineText}`}
                        {...teaserProps}
                        innerRef={ref}
                        webRel={nofollowExternal}
                        webTarget={webTarget}
                        teaserIcon={teaser.teaserIcon}
                    />
                )
            case TeaserSizeTypes.LIST:
                return (
                    <TeaserComponent
                        variant="HorizontalTeaserM"
                        testID={`${teaser.type}_listTeaser_${teaserProps.headlineText}`}
                        {...teaserProps}
                        innerRef={ref}
                        webTarget={webTarget}
                        webRel={nofollowExternal}
                    />
                )
        }
    }, [
        size,
        teaserProps,
        expand,
        headlineNumberOfLines,
        teaser,
        webTarget,
        asPath,
        isItTwoTeasersRightColumn,
    ])
}

export default Teaser
